import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReviews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/reviews', { params: queryParams })
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    fetchReview(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/reviews/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createReview(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/reviews', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReview(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/reviews/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidReview(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/reviews/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        params.branch_ids.forEach(branchId => urlParams.append('branch_ids[]', branchId))
        if (params.start_date) urlParams.append('start_date', params.start_date)
        if (params.end_date) urlParams.append('end_date', params.end_date)

        fetch(`${baseURL}/api/v1/reviews.pdf?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  },
}
