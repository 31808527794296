<template>
  <v-row class="app-preview pa-6 px-lg-16 ma-0">
    <v-col
      cols="12"
      align="right"
      class="py-0"
      :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
    >
      <app-bar-theme-switcher></app-bar-theme-switcher>
    </v-col>
    <v-col
      cols="12"
      md="9"
      :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
    >
      <v-card class="app-review-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead :width="'100%'" />
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1">
              <v-row>
                <!-- Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                  class="offset-sm-0"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Car Number:
                  </p>
                  <div class="d-flex align-center">
                    <v-autocomplete
                      ref="receiptMetaList"
                      v-model="review.receipt_id"
                      :loading="searchLoading"
                      :items="receiptMetas"
                      :search-input.sync="searchText"
                      cache-items
                      item-value="id"
                      item-text="description"
                      single-line
                      outlined
                      dense
                      hide-details
                      placeholder="Start Typing to Search"
                      class="search-container"
                    >
                      <template v-slot:item="{item, on, attrs}">
                        <v-list-item
                          class="list-selection"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="text-selection">
                              {{ item.description }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-tooltip
                      :left="$vuetify.breakpoint.smAndUp"
                      :top="$vuetify.breakpoint.xsOnly"
                      :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                      :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                      :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                      max-width="250"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="30"
                          class="ml-3 hover-pointer"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="25">
                            {{ icons.mdiAlertCircleOutline }}
                          </v-icon>
                        </v-avatar>
                      </template>
                      <h3 class="my-2">
                        {{ t('How to use the search bar') }}
                      </h3>
                      <p class="mb-1">
                        {{ t('You can search using') }}:
                      </p>
                      <ul class="mb-2">
                        <li>
                          {{ t('Receipt No.') }}
                        </li>
                        <li>
                          {{ t('Name') }}
                        </li>
                        <li>
                          {{ t('Car No.') }}
                        </li>
                      </ul>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <!-- Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                  class="offset-sm-0"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Name:
                  </p>
                  <v-text-field
                    v-model="review.name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Name"
                  ></v-text-field>
                </v-col>

                <!-- Contact Number -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Contact No.:
                  </p>
                  <v-form ref="contactForm">
                    <v-text-field
                      v-model="review.contact_number"
                      single-line
                      dense
                      outlined
                      type="number"
                      :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                      hide-details="auto"
                      placeholder="Contact Number"
                      prefix="+673"
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-row>

              <v-row>
                <!-- Collection Date -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Date of Collection:
                  </p>
                  <v-menu
                    v-model="isDateMenuOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :append-icon="icons.mdiCalendar"
                        :value="new Date(review.collection_date).toLocaleDateString()"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="review.collection_date"
                      color="primary"
                      :first-day-of-week="1"
                      disabled
                      @input="isDateMenuOpen = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <!-- Rating -->
                <v-col
                  cols="12"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Rate your experience:
                  </p>
                  <v-rating
                    v-model="review.rating"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row>
                <!-- Suggestions -->
                <v-col
                  cols="12"
                  :class="{ 'px-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Suggestions for improvement, if any:
                  </p>
                  <v-textarea
                    v-model="review.suggestions"
                    placeholder="Suggestions for improvement"
                    dense
                    outlined
                    rows="5"
                    hide-details
                    class="mt-3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
      :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="createReview()"
          >
            {{ t('Confirm Review') }}
          </v-btn>

          <div class="text-center mt-4">
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiAlertCircleOutline, mdiCalendar, mdiPaperclip } from '@mdi/js'
import {
  inject,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'

// import { useRouter } from '@core/utils'

import { Letterhead } from '@/components/apps/previews'
import Alert from '@/components/Alert'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import reviewStoreModule from './reviewStoreModule'
import receiptStoreModule from '../receipt/receiptStoreModule'

export default {
  components: {
    Alert,
    Letterhead,
    AppBarThemeSwitcher,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')

    // const { router } = useRouter()

    const REVIEW_APP_STORE_MODULE_NAME = 'app-review'
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'

    if (!store.hasModule(REVIEW_APP_STORE_MODULE_NAME)) {
      store.registerModule(REVIEW_APP_STORE_MODULE_NAME, reviewStoreModule)
    }

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    // Properties
    const review = ref({
      name: null,
      contact_number: null,
      rating: null,
      collection_date: new Date().toISOString().substring(0, 10),
      receipt_id: null,
    })
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const dateToday = new Date().toLocaleDateString('en-SG')
    const loading = ref(false)
    const searchLoading = ref(false)
    const isDateMenuOpen = ref(false)
    const receiptMetas = ref([])
    const receiptMetaList = ref(null)
    const searchText = ref(null)
    const contactForm = ref(null)

    // Methods
    const fetchReceiptMetas = query => {
      if (!query || (query && query.includes('-'))) return

      searchLoading.value = true
      store
        .dispatch('app-receipt/fetchReceiptMetas', {
          search_text: query,
        })
        .then(response => {
          searchLoading.value = false
          const { data } = response.data
          receiptMetas.value = data.filter(r => r.is_reviewed === false)
        })
        .catch(error => {
          searchLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipt metas. Please refresh!')
        })
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const clearData = () => {
      review.value = {
        name: null,
        contact_number: null,
        rating: null,
        collection_date: new Date().toISOString().substring(0, 10),
        receipt_id: null,
      }
      contactForm.value.reset()
    }
    const createReview = () => {
      loading.value = true
      errors.value = []
      store
        .dispatch('app-review/createReview', { review: review.value })
        .then(response => {
          snackbarService.success(response.data.message)
          loading.value = false
          clearData()
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving review. Please refresh!')
        })
    }

    // Watch
    let timer = null
    watch(searchText, query => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        receiptMetaList.value.cachedItems = []
        fetchReceiptMetas(query)
      }, 500)
    })

    // Mounted
    onMounted(() => {
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(REVIEW_APP_STORE_MODULE_NAME)) store.unregisterModule(REVIEW_APP_STORE_MODULE_NAME)
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      review,
      createReview,
      errors,
      userData,
      dateToday,
      loading,
      searchLoading,
      isNumber,
      isDateMenuOpen,
      receiptMetas,
      receiptMetaList,
      searchText,
      contactForm,

      icons: {
        mdiCalendar,
        mdiPaperclip,
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.text-selection {
  text-wrap: wrap;
}
.list-selection {
  height: auto !important;
}
</style>
